import { getAction, getByIdAction, postAction, putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/live/itinerary/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";
const getItienPageListApi = (params) => getAction(baseURL + '/pageList', params);
const getItienSelectByIdApi = (params) => getByIdAction(baseURL + '/selectById', params);
const addItienDataApi = (params) => postAction(baseURL + '/add', params);
const editItienDataApi = (params) => putAction(baseURL + '/edit', params);
const deletItieneByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);
export {
    getItienPageListApi,
    getItienSelectByIdApi,
    addItienDataApi,
    editItienDataApi,
    deletItieneByIdApi
}