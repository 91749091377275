<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <el-form :model="dataFrom" label-width="100px" ref="dataFrom">
      <el-row :gutter="24">
          <el-col :span="8">
          <el-form-item label="起点地址" prop="areaValue">
            <el-cascader
              v-model="areaValue"
              :options="areaList"
              :props="{ value: 'areaCode', label: 'areaName'}"
              @change="handleChange"
              filterable
              ref="cascader"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="目的地" width="120px" prop="tripId">
            <el-select v-model="dataFrom.tripId" filterable placeholder="请选择" style="width:90%">
              <el-option
                v-for="item in tripList"
                :key="item.uniqueId"
                :label="item.fullName"
                :value="item.uniqueId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      

        <el-col :span="8">
          <el-form-item label="具体地址" prop="specificAddress">
            <el-input  v-model="dataFrom.specificAddress" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="时间" prop="day" label-width="80px">
            <el-input-number  v-model="dataFrom.day" />天 &nbsp;
            <el-input-number  v-model="dataFrom.night" />夜
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否提前到" prop="early">
            <el-switch
              v-model="dataFrom.early"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="标题" prop="title">
            <el-input v-model="dataFrom.title" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="预估费用" width="120px" prop="myBudgetCost">
            <el-input-number v-model="dataFrom.myBudgetCost" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="标签" prop="labels">
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleCloseTag(tag)"
            >{{tag}}</el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="dataFrom.labels"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="描述" width="120px" prop="description">
            <el-input v-model="dataFrom.description" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="备注" width="120px" prop="remark">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="dataFrom.remark"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="是否置顶" prop="isTopping">
            <el-switch
              v-model="dataFrom.isTopping"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="editSaveButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getListApi } from "@/api/life/trip/trip";

import {
  addItienDataApi,
  editItienDataApi,
  getItienSelectByIdApi
} from "@/api/life/trip/itinerary.js";
export default {
  name: "saveEdit",
  data() {
    return {
      areaList: {},
      areaValue: [],
      gradeList: {},
      dialogVisible: false,
      dataFrom: {},
      dataFromRef: {},
      ids: null,
      types: 1,
      editShow: true,
      title: null,
      dynamicTags: [],
      inputVisible: false,
      tripIds: null,
      tripList: []
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    show(tripIds, type, id) {
      this.areaList = this.$parent.areaList;
      this.initEnumList();
      this.tripIds = tripIds;
      this.dialogVisible = true;
      this.ids = id;
      this.types = type;
      this.dataFrom.early=1
      if (type == 2) {
        this.selectDataById();
      }
      console.log(tripIds, this.ids);
    },
    selectDataById() {
      getItienSelectByIdApi(this.ids).then(res => {
        this.dataFrom = res.result;
        this.areaValue = res.result.areaList;
        if (null != res.result.labels && "" != res.result.labels) {
          this.dynamicTags = res.result.labels.split(",");
        }
      });
    },
    cancel() {
      this.dataFrom = {};
      this.dataFromRef = {};
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectItineraryButton();
      this.tripIds = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
      this.$parent.activeName = "second";
      this.areaValue = [];
      this.dynamicTags = [];
      // this.$parent.tripIds = null;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    editSaveButton() {
      this.dataFrom.labels = this.dynamicTags.toString();
      if (this.types == 1) {
        addItienDataApi(this.dataFrom).then(res => {
          this.cancel();
        });
      } else {
        editItienDataApi(this.dataFrom).then(res => {
          this.cancel();
        });
      }
    },
    initEnumList() {
      getListApi().then(res => {
        this.tripList = res.result;
      });
    },
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.dataFrom.labels;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.dataFrom.labels = "";
    },
    handleChange() {
      let data = this.$refs.cascader.getCheckedNodes()[0].pathLabels;
      let center = this.$refs.cascader
        .getCheckedNodes()[0]
        .data.center.split(",");
      this.dataFrom.latitude = center[0];
      this.dataFrom.longitude = center[1];

      this.dataFrom.startProvinceName = data[0];
      this.dataFrom.startCityName = data[1];

      this.dataFrom.startProvinceCode = this.areaValue[0];
      this.dataFrom.startCityCode = this.areaValue[1];
    }
  }
};
</script>
<style lang="less" scoped>
</style> 