import { getAction,getByIdAction,postAction,putAction ,deleteByIdAction} from '@/api/common/request'
const baseURL = 'xun/work/live/trip/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";
const getListApi=(params)=>getAction(baseURL+'/list',params);

const getPageListApi=(params)=>getAction(baseURL+'/pageList',params);

const getSelectByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);


const addDataApi=(params)=>postAction(baseURL+'/add',params);


const editDataApi=(params)=>putAction(baseURL+'/edit',params);

const deleteByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

export{
    getPageListApi,
    getSelectByIdApi,
    getListApi,
    addDataApi,
    editDataApi,
    deleteByIdApi
}